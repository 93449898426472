import React from 'react';
import { ImageStyle } from 'react-native';
import { ThemedIcon } from '../../components/themed-icon.component';
import {
  AssetArticlesDarkIcon,
  AssetArticlesIcon,
  AssetAuthDarkIcon,
  AssetAuthIcon,
  AssetCardDarkIcon,
  AssetCardIcon,
  AssetDashboardsDarkIcon,
  AssetDashboardsIcon,
  AssetEcommerceDarkIcon,
  AssetEcommerceIcon,
  AssetMessagingDarkIcon,
  AssetMessagingIcon,
  AssetSocialDarkIcon,
  AssetSocialIcon,
  RepAppIcon01,
  RepAppIcon02,
} from '../../components/icons';
import { MenuItem } from '../../model/menu-item.model';
import { AppRoute } from '../../app/app-routes';

export interface LayoutData extends MenuItem {
  route: string | string [];
}

export const data: LayoutData[] = [
  {
    title: 'Kết quả xét nghiệm',
    route: AppRoute.CHAM_CONG_LIST,
    icon: (style: ImageStyle) => {
      return React.createElement(
        ThemedIcon,
        { ...style, light: RepAppIcon01, dark: RepAppIcon01 },
      );
    },
  },
  {
    title: 'Danh mục xét nghiệm',
    route: [AppRoute.CHAM_CONG_LIST, AppRoute.SPEC_LIST],
    icon: (style: ImageStyle) => {
      return React.createElement(
        ThemedIcon,
        { ...style, light: RepAppIcon02, dark: RepAppIcon02 },
      );
    },
  },
  // {
  //   title: 'Auth',
  //   route: 'Auth',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetAuthIcon, dark: AssetAuthDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Social',
  //   route: 'Social',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetSocialIcon, dark: AssetSocialDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Articles',
  //   route: 'Articles',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetArticlesIcon, dark: AssetArticlesDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Messaging',
  //   route: 'Messaging',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetMessagingIcon, dark: AssetMessagingDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Dashboards',
  //   route: 'Dashboards',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetDashboardsIcon, dark: AssetDashboardsDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Ecommerce',
  //   route: 'Ecommerce',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetEcommerceIcon, dark: AssetEcommerceDarkIcon },
  //     );
  //   },
  // },
];
