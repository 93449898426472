export enum AppRoute {
  AUTH = "Auth",
  SIGN_IN = "SignIn2",
  HOME = "pages",
  DASHBOARD = "dashboard",
  THEME = "Themes",
  ROOT = "main",
  SALE_INVOICE = "Sale Invoice List",
  SALE_INVOICE_LIST = "Sale Invoice List",
  CHAM_CONG_LIST = "xem-yeu-cau",
  CHAM_CONG_CREATE = "tao-cham-cong",
  YEU_CAU_DETAIL = "xem-chi-tiet",
  PASSWORD = "change-password",
  IMAGE_DETAIL = "image-detail",
  SPEC_LIST = "spec-list",
}
