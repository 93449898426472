import React from 'react';
import { Dimensions, ImageBackground, StyleSheet, View } from 'react-native';
import { Button, Input, Layout, Text } from '@ui-kitten/components';
import { EmailIcon } from './extra/icons';
import { KeyboardAvoidingView } from './extra/3rd-party';

export default ({ navigation }): React.ReactElement => {

  const [email, setEmail] = React.useState<string>();

  const onResetPasswordButtonPress = (): void => {
    navigation && navigation.goBack();
  };

  return (
    <KeyboardAvoidingView>
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ImageBackground
        style={styles.container}
        source={require('../../../assets/images/bg.jpg')}>
        <Text
          style={styles.forgotPasswordLabel}
          category='h4'
          status='control'>
          Forgot Password
        </Text>
        <Text
          style={styles.enterEmailLabel}
          status='control'>
          Please enter your email address
        </Text>
        <View style={styles.formContainer}>
          
          <Input
            placeholder='Email'
            accessoryRight={EmailIcon}
            value={email}
            onChangeText={setEmail}
            />
        </View>
        <Button
          size='giant'
          onPress={onResetPasswordButtonPress}>
          RESET PASSWORD
        </Button>
      </ImageBackground>
      </View>

    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'background-basic-color-1',
    maxWidth: Dimensions.get('window').width > 500 ? 500 : Dimensions.get('window').width,
    minWidth: 390,
    alignSelf: 'center',
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  formContainer: {
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 24,
    backgroundColor: 'none'
  },
  forgotPasswordLabel: {
    color:"#1e3050",
    zIndex: 1,
    alignSelf: 'center',
    marginTop:300,
  },
  enterEmailLabel: {
    color:"#1e3050",
    zIndex: 1,
    alignSelf: 'center',
    marginTop: 64,
  },
});
