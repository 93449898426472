import { Alert, NativeModules } from 'react-native';
import { AppConst } from '../app/app-const';
import { AppStorage } from './app-storage.service';
import { authorize, AuthorizeResult, prefetchConfiguration, refresh, revoke } from 'react-native-app-auth';
import { AppRoute } from '../app/app-routes';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { ResponseType } from 'expo-auth-session';
import { CommonActions } from '@react-navigation/native';
import { AppFetch } from './app-fetch.service';
import Toast from 'react-native-root-toast';
import moment from 'moment';

// WebBrowser.maybeCompleteAuthSession();

const useProxy = false;

const redirectUri = AuthSession.makeRedirectUri({
  useProxy,
});
// const requestInfo = {
//   clientId: 'Xbook_pro_dong_tam_mobile_dev',
//   redirectUri,
//   shouldAutoExchangeCode: false,
//   clientSecret: 'false',
//   responseType: ResponseType.Code,
//   scopes: ['openid', 'profile', 'email', 'offline_access'],
// };

// const issuer = 'https://xbaccount.b24.vn';


export class AppAuthService {

  // static getConfig = () => [requestInfo, issuer]

  static handleAuthorize = async (email, password, navigation, config) => {
    console.log(email);
    console.log(password);
    // const discovery = await AuthSession.fetchDiscoveryAsync(issuer);
    // const [request, result, promptAsync] = config;
    // var response = await promptAsync({ useProxy });
    // if (response && response?.type === 'success') {

    //   const auth = response.params;
    //   var token = await AuthSession.exchangeCodeAsync(
    //     {
    //       code: auth.code,
    //       redirectUri: redirectUri,
    //       clientId: requestInfo.clientId,
    //       extraParams: {
    //         code_verifier: request.codeVerifier,
    //       },
    //       ...requestInfo
    //     }, discovery);
    //   const userInfo = await AuthSession.fetchUserInfoAsync({ accessToken: token.accessToken }, discovery);
    //   AppStorage.set(AppConst.USER_IDENTITY_INFO, JSON.stringify(auth));
    //   AppStorage.set(AppConst.USER_TOKEN, JSON.stringify(token));
    //   AppStorage.set(AppConst.USER_INFO, JSON.stringify(userInfo));
    //   // navigation && navigation.navigate(AppRoute.HOME);
    // }
    const model: any = {
      Email: email,
      Password: password,
    };
    AppFetch.post('LoginMobile', model).then((rs: {success: Boolean, email: string, name: string, displayName?: string}) => {
      if (rs.success === true) {

        AppStorage.set(AppConst.USER_INFO, JSON.stringify({ loginDate: moment().toString(),name: (rs.email as string).toLocaleLowerCase(), companyName: rs.name, displayName: rs?.displayName, phoneNumber: email })).then(x => {

          AppStorage.set(AppConst.USER_IDENTITY_INFO, JSON.stringify({ code: (rs.email as string).toLocaleLowerCase(), companyName: rs.name }));

          navigation && navigation.navigate(AppRoute.ROOT, { screen: AppRoute.HOME });
        });
      } else {
        Alert.alert('Vui lòng nhập đúng tài khoản và password!');
        Toast.show('Vui lòng nhập đúng tài khoản và password!');
      }
    });
  }

  static isLoggedIn: () => Promise<boolean> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_IDENTITY_INFO);
    const token = data?.code;
    return !!token;
  }

  static getToken: () => Promise<string> = async () => {
    const data = await AppStorage.get<AuthSession.TokenResponse>(AppConst.USER_TOKEN);
    const token = data?.accessToken;
    return token;
  }

  static getName: () => Promise<string> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    const token = data?.name;
    return token;
  }
}
