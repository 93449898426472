import React, { useCallback, useMemo, useRef } from 'react';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
import { data } from './extra/data';

export default ({ navigation }): LayoutListElement => {
  
  const onItemPress = (index: number): void => {
    // navigation.navigate(data[index].route);
  };

  const onItemLongPress = (index: number): void => {
    // navigation.navigate(data[index].route);
  };

  return (
    <LayoutList
      data={data}
      onItemPress={onItemPress}
      onItemLongPress={onItemLongPress}
    />
  );
};