import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { View, TouchableWithoutFeedback, Platform, Alert, ImageBackground, Dimensions } from 'react-native';
import { Button, Input, Layout, StyleService, Text, useStyleSheet, Icon, Avatar } from '@ui-kitten/components';
import { PersonIcon,PassWordIcon } from './extra/icons';
import { PhoneIcon } from '../sign-in-5/extra/icons';
import { KeyboardAvoidingView } from './extra/3rd-party';
import { AppRoute } from '../../../app/app-routes';
import * as WebBrowser from 'expo-web-browser';
import * as SecureStore from 'expo-secure-store';
import Constants from 'expo-constants';
import { authorize, AuthorizeResult, prefetchConfiguration, refresh, revoke } from 'react-native-app-auth';
import { AppConst } from '../../../app/app-const';
import { AppStorage } from '../../../services/app-storage.service';
import { AppAuthService } from '../../../services/app-auth.service';
import * as AuthSession from 'expo-auth-session';
import { CommonActions } from '@react-navigation/native';

// Note "offline_access" scope is required to get a refresh token
// const config = {
//   issuer: 'https://xbaccount.b24.vn',
//   clientId: 'Xbook_pro_dong_tam_mobile_dev',
//   redirectUrl: 'com.xbookdongtam.auth:/oauthredirect',
//   scopes: ['openid', 'profile', 'offline_access'],
// };

// const defaultAuthState = {
//   hasLoggedInOnce: false,
//   provider: '',
//   accessToken: '',
//   accessTokenExpirationDate: '',
//   refreshToken: ''
// };
// WebBrowser.maybeCompleteAuthSession();
export default ({ navigation }): React.ReactElement => {
  //#region Auth

  // const [authState, setAuthState] = useState(defaultAuthState);
  // React.useEffect(() => {
  //   prefetchConfiguration({
  //     warmAndPrefetchChrome: true,
  //     ...config,
  //   });
  // }, []);

  // const handleAuthorize = useCallback(
  //   async () => {
  //     await AppAuthService.handleAuthorize(navigation, {});
  // try {
  //   const newAuthState = await authorize({
  //     ...config,
  //   });
  //   const result = {
  //     hasLoggedInOnce: true,
  //     provider: 'identityserver',
  //     ...newAuthState,
  //   };

  //   setAuthState(result);
  //   AppStorage.set(AppConst.USER_IDENTITY_INFO, JSON.stringify(result));

  //   navigation && navigation.navigate(AppRoute.HOME);
  // } catch (error) {
  //   Alert.alert('Failed to log in', error.message);
  // }
  // },
  //   [authState],
  // );

  // const handleRefresh = useCallback(async () => {
  //   try {
  //     const newAuthState = await refresh(config, {
  //       refreshToken: authState.refreshToken,
  //     });

  //     setAuthState(current => ({
  //       ...current,
  //       ...newAuthState,
  //       refreshToken: newAuthState.refreshToken || current.refreshToken,
  //     }));
  //   } catch (error) {
  //     Alert.alert('Failed to refresh token', error.message);
  //   }
  // }, [authState]);

  // const handleRevoke = useCallback(async () => {
  //   try {
  //     await revoke(config, {
  //       tokenToRevoke: authState.accessToken,
  //       sendClientId: true,
  //     });

  //     setAuthState(defaultAuthState);
  //   } catch (error) {
  //     Alert.alert('Failed to revoke token', error.message);
  //   }
  // }, [authState]);

  // const showRevoke = useMemo(() => {
  //   if (authState.accessToken) {
  //     if (config.issuer || config.serviceConfiguration.revocationEndpoint) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }, [authState]);

  //#endregion

  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false)

  const styles = useStyleSheet(themedStyles);

  const onSignUpButtonPress = (): void => {
    navigation && navigation.navigate('SignUp2');
  };

  let authRequest = {};

  if (Platform.OS == 'web') {
    // const [requestInfo, issuer] = AppAuthService.getConfig();
    // const discovery = AuthSession.useAutoDiscovery(issuer);
    // // Create and load an auth request
    // authRequest = AuthSession.useAuthRequest(
    //   requestInfo,
    //   discovery
    // );
  }
  
  const onSignInButtonPress = (): void => {
    AppAuthService.handleAuthorize(email, password, navigation, authRequest).then(() => {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,//the stack index
          routes: [
            { name: 'pages' },//to go to initial stack screen
          ],
        })
      );
      // navigation && navigation.navigate(AppRoute.ROOT, { screen: AppRoute.HOME });
    });
  };

  const onForgotPasswordButtonPress = ()=> {
    setShow(!show)
  };

  const onPasswordIconPress = (): void => {
    setPasswordVisible(!passwordVisible);
  };

  const renderPasswordIcon = (props): ReactElement => (
    <TouchableWithoutFeedback onPress={onPasswordIconPress}>
      <Icon {...props} name={passwordVisible ? 'eye-off' : 'eye'} />
    </TouchableWithoutFeedback>
  );
  return (
    <KeyboardAvoidingView style={styles.container}>
      <View style={{ flex: 1, flexDirection: 'column', height: 100}}>
      <ImageBackground  source={require('../../../assets/images/bg.jpg')} resizeMode="stretch" style={{
      flex: 1,
      // height: 140,
      resizeMode: 'cover',
      justifyContent: 'center',
    }}>
       
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ImageBackground source={require('../../../assets/images/image-app-icon.png')} style={{ width: 115, height: 118, marginTop: 130,  justifyContent: 'center'}}></ImageBackground>
      </View>
      
      <Layout
        style={styles.formContainer}
        level='1'>
        <Input
          placeholder='Email/ SĐT'
          accessoryRight={PersonIcon}
          value={email}
          onChangeText={setEmail}
        />
        <Input
          style={styles.passwordInput}
          placeholder='Password'
          accessoryRight={renderPasswordIcon}
          value={password}
          secureTextEntry={!passwordVisible}
          onChangeText={setPassword}
        />
        <View style={styles.forgotPasswordContainer}>
          <Button
            style={styles.forgotPasswordButton}
            appearance='ghost'
            status='basic'
            onPress={onForgotPasswordButtonPress}>
            Quên mật khẩu?
          </Button>
        </View>
      </Layout>
      <Button
        style={styles.signInButton}
        size='giant'
        onPress={onSignInButtonPress}>
        ĐĂNG NHẬP
      </Button>
      </ImageBackground >
      </View>
          {show && (
            <View style={styles.infoContainer}>
              <View style={styles.infoContainerBox}>
                <Text style={styles.infoContainerBoxClose} onPress={onForgotPasswordButtonPress}>x</Text>
                <View style={styles.infoContainerBoxText}>
                  <h1 style={styles.infoContainerBoxTextH1}>QUÊN MẬT KHẨU</h1>
                  <p style={styles.infoContainerBoxTextP}>
                    Nếu bạn muốn khôi phục mật khẩu hãy liên hệ Holine để được hỗ trợ
                  </p>
                  <View style={styles.infoContainerBoxPhone} accessoryRight={PassWordIcon}>
                    
                    <p>032 761 5454</p>
                  </View>
                </View>
              </View>
            </View>
          )}
    </KeyboardAvoidingView>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'background-basic-color-1',
    maxWidth: Dimensions.get('window').width > 500 ? 500 : Dimensions.get('window').width,
    minWidth: 390,
    flex: 1,
    alignSelf: 'center'
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 216,
    backgroundColor: 'color-primary-default',
  },
  formContainer: {
    flex: 1,
    paddingTop: 32,
    paddingHorizontal: 16,
    backgroundColor: 'none'
  },
  signInLabel: {
    marginTop: 16,
  },
  signInButton: {
    marginHorizontal: 16,
    marginBottom: 50,
  },
  signUpButton: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  forgotPasswordContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  passwordInput: {
    marginTop: 16,
  },
  forgotPasswordButton: {
    paddingHorizontal: 0,
  },
  infoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainerBox: {
    width: 300,
    minHeight: 200,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
  },
  infoContainerBoxClose: {
    position: 'absolute',
    right: 8,
    top: 4,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // border: '1px solid #333',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    cursor: 'pointer',
    borderRadius: 100 
  },
  infoContainerBoxText: {
    color: 'rgb(34, 43, 69)',
  },
  infoContainerBoxTextH1: {
    fontSize: 18,
    textAlign:'center',
    marginBottom:0,
  },
  infoContainerBoxTextP: {
    textAlign:'center',
    color: 'rgba(0,0,0,0.5)'
  },
  infoContainerBoxPhone: {
    backgroundColor: '#D9D9D9',
    display: 'flex',
    width: "100%",
    height: 40,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

