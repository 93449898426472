import { LayoutItem } from '../../../../model/layout-item.model';

export interface SaleInvoiceListData extends LayoutItem {
}

export const data: SaleInvoiceListData[] = [
  {
    title: 'CÔNG TY CỔ PHẦN KIZUNA JV1',
    rightTitle: '5,000,000',
    description: 'PBH-0001-(31/07/2022)',
    secondaryText: 'tiền đầu kỷ',
    image: null,
    status: 'success',
  },
  {
    title: 'CÔNG TY CỔ PHẦN KIZUNA JV',
    rightTitle: '5,000,000',
    description: 'PBH-0001-(31/07/2022)',
    secondaryText: 'tiền đầu kỷ',
    image: null,
    status: 'warning',
  },
  {
    title: 'CÔNG TY CỔ PHẦN KIZUNA JV',
    rightTitle: '5,000,000',
    description: 'PBH-0001-(31/07/2022)',
    secondaryText: 'tiền đầu kỷ',
    image: null,
    status: 'warning',
  },
  {
    title: 'CÔNG TY CỔ PHẦN KIZUNA JV',
    rightTitle: '5,000,000',
    description: 'PBH-0001-(31/07/2022)',
    secondaryText: 'tiền đầu kỷ',
    image: null,
    status: 'info',
  },
  {
    title: 'CÔNG TY CỔ PHẦN KIZUNA JV',
    rightTitle: '105,000,000',
    description: 'PBH-0001-(31/07/2022)',
    secondaryText: 'tiền đầu kỷ',
    image: null,
    status: 'success',
  },
];
