import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { PasswordScreen } from '../scenes/password/password.component';

const Stack = createStackNavigator();

export const PasswordNavigator = (): React.ReactElement => (
  <Stack.Navigator headerMode='none'>
    <Stack.Screen name='change-password' component={PasswordScreen}/>
  </Stack.Navigator>
);
