import React, { useCallback, useMemo, useRef } from 'react';
import { ListRenderItemInfo, StyleSheet, View } from 'react-native';
import { Button, Card, CardElement, CheckBox, Input, Layout, List, ListElement, ListProps, Text, Toggle } from '@ui-kitten/components';
import { RequestListItem, ChamCongItemDetail } from '../../../../model/cham-cong-item.model';
import Animated, { Extrapolate, interpolate } from 'react-native-reanimated';
import { SaveOutlineIcon } from '../../../../../src/components/icons';
import moment from 'moment';

export interface ChamCongListProps extends Omit<ListProps, 'renderItem'> {
  data: RequestListItem[];
  onItemPress: (index: number) => void;
  onItemLongPress?: (index: number) => void;
  onValueTextChange?: (value: string, index: number) => void;
  onItemSubmit?: (index: number) => void;
  onItemViewPdf?: (index: number) => void;
}

export type ChamCongListElement = React.ReactElement<ChamCongListProps>;

export const ChamCongList = (props: ChamCongListProps): ListElement => {
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const { contentContainerStyle, onItemPress, onItemLongPress, onValueTextChange, onItemSubmit, onItemViewPdf, ...listProps } = props;

  const renderItem = (info: ListRenderItemInfo<RequestListItem>): CardElement => (
    <Card
      style={styles.itemContainer}
      onPress={() => onItemPress(info.index)}
      onLongPress={() => { onItemLongPress(info.index); }}>
      <Layout style={styles.layoutContainer}>

        <Layout style={{ ...styles.layout, flex: 1 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='H6'>
            Số yêu cầu: {info?.item.requestNo}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 1 }} level='1'>
          <Text style={styles.text} status='primary' ellipsizeMode='tail' numberOfLines={1}
            category='P2'>
            KQ/ Tổng chỉ tiêu: {info?.item.Recorded} / {info?.item.TotalSpec}
          </Text>
        </Layout>

        {/* <Layout style={{ ...styles.layout, flex: 2, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s1'>
            {info.item.detail.filter(x => x.daDuyet).map(x => x.soGioCong).reduce((value, total) => value + total, 0)}
          </Text>
        </Layout> */}

      </Layout>
      <Layout style={styles.layoutContainer}>

        <Layout style={{ ...styles.layout, flex: 5, marginBottom: 2, marginTop: 2 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='P2'>
            {info.item.Customer}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 1 }} level='1'>
          <Text style={styles.text} status='warning' ellipsizeMode='tail' numberOfLines={1}
            category='P2'>
            {info.item.TotalSample} Mẫu
          </Text>
        </Layout>

      </Layout>
      <Layout style={styles.layoutContainer}>

        <Layout style={{ ...styles.layout, flex: 1 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='P2'>
            Ngày nhận: {moment(info.item.receivceDate, 'MM/DD/YYYY').format('DD/MM/YYYY')}
          </Text>
        </Layout>

        <Layout style={{ ...styles.layout, flex: 1 }} level='1'>
          <Text style={styles.text} ellipsizeMode='tail' numberOfLines={1}
            category='P2'>
            Ngày trả: {moment(info.item.ExpectationDate, 'MM/DD/YYYY').format('DD/MM/YYYY')}
          </Text>
        </Layout>
      </Layout>

      <Layout style={{ ...styles.layoutContainer, flex: 2, flexDirection: "column" }}>
        <Layout style={{ ...styles.layout, flex: 2, minHeight: 20 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='P2'>
            {info.item.techComment ?? ""}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, alignSelf: 'flex-end' }} level='1'>
          {(info.item.isPdfExist && info?.item.Recorded > 0)
            ? <Button accessoryLeft={SaveOutlineIcon} style={{ borderRadius: 8, width: 99.5 }} size='small' onPress={() => onItemViewPdf(info.index)}>PDF</Button>
            : <Button status='danger' style={{ borderRadius: 8 }} size='small' >Chưa có KQ</Button>
          }
        </Layout>

        {/* <Layout style={{ ...styles.layout, flex: 2 }} level='1'>
          <Button
            style={{ borderRadius: 8, marginLeft: 20 }} size='small' onPress={() => onItemSubmit(info.index)}>Ghi chú</Button>
        </Layout> */}
      </Layout>
    </Card>
  );

  return (
    <List
      {...listProps}
      contentContainerStyle={[styles.container, contentContainerStyle]}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    paddingVertical: 4,
  },
  itemContainer: {
    marginVertical: 4,
    marginHorizontal: 0,
  },
  itemDescription: {
    marginTop: 2,
  },
  text: {
    textAlign: 'right',
  },
  secondaryText: {
  },
  layoutContainer: {
    flex: 1,
    flexDirection: 'row',
    fontSize: 10,
  },
  layout: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bottomSheetModal: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'column',
  },
  bottomSheetModalItem: {
    flex: 1,
    width: '100%',
  },
  bottomSheetModalItemText: {
    textAlign: 'center',
  },
});
