import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { SaleInvoiceListScreen } from '../scenes/sale-invoice/sale-invoice-list.component';
import { AppRoute } from '../app/app-routes';

const TopTab = createMaterialTopTabNavigator();
const Stack = createStackNavigator();


export const SaleInvoiceNavigator = (): React.ReactElement => (
  <Stack.Navigator>
    <Stack.Screen name={AppRoute.SALE_INVOICE_LIST} component={SaleInvoiceListScreen}/>
  </Stack.Navigator>
);
