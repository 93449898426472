import { BottomNavigation, BottomNavigationTab, Button, CalendarRange, Card, Divider, IndexPath, Input, Layout, ModalService, RangeDatepicker, Select, SelectItem, StyleService, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import React from 'react';
import { ArrowIosBackIcon, MenuIcon, SearchIcon } from '../../components/icons';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import ContentView from '../../layouts/cham-cong/cham-cong-list';
import { Animated, Dimensions, ImageBackground, ScrollView, StyleSheet, View, ViewStyle } from 'react-native';
import { BrandBottomNavigation } from '../../components/brand-bottom-navigation.component';
import { AppRoute } from '../../app/app-routes';
import moment from 'moment';
import AppUtils from '../../app/app-utils';
import { AppAuthService } from '../../../src/services/app-auth.service';
import { AppFetch } from '../../../src/services/app-fetch.service';
import { AppStorage } from '../../../src/services/app-storage.service';
import { AppConst } from '../../../src/app/app-const';

const useVisibilityAnimation = (visible: boolean): ViewStyle => {

  const animation = React.useRef<Animated.Value>(new Animated.Value(visible ? 1 : 0));

  React.useEffect(() => {
    Animated.timing(animation.current, {
      duration: 200,
      toValue: visible ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [visible]);

  return {
    transform: [
      {
        // @ts-ignore
        translateY: animation.current.interpolate({
          inputRange: [0, 1],
          outputRange: [50, 0],
        }),
      },
    ],
    position: visible ? null : 'absolute',
  };
};
const defaultRange: CalendarRange<Date> = {
  startDate: moment().startOf('month').toDate(),
  endDate: moment().add(1, 'days').toDate(),
};
export const ChamCongListScreen = ({ route, navigation }): React.ReactElement => {
  const {farmName} = route.params;
  const transforms = useVisibilityAnimation(true);
  const [range, setRange] = React.useState<CalendarRange<Date>>(defaultRange);
  const [keyword, setKeyword] = React.useState<string>(farmName);
  const [type, setType] = React.useState<string>('');
  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={ArrowIosBackIcon}
      onPress={() => AppUtils.goBack(navigation, AppRoute.HOME)}
    />
  );
  let modalID = '';
  const renderRightAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={SearchIcon}
      onPress={() => {
        const contentElement = <YeuCauSearchForm farmName={farmName} onSubmit={onSubmit}></YeuCauSearchForm>;
        modalID = ModalService.show(contentElement, { onBackdropPress: hideModal, backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } });
      }}
    />
  );
  const hideModal = () => {
    ModalService.hide(modalID);
  };
  const onSubmit = (_range, _keyword, _type) => {
    setRange(_range);
    setKeyword(_keyword);
    setType(_type);
    hideModal();
  };
  return <SafeAreaLayout style={{ flexDirection: 'column', flex: 1, ...styles.container }}
    insets='top'>
    <ImageBackground
      source={require('../../assets/images/bg-02.jpg')}
      resizeMode="stretch" style={{
        flex: 1,
        height: Dimensions.get("window").height,
        width: '100%',
        justifyContent: 'center',
      }}>

      <TopNavigation
        title={'Kết quả xét nghiệm\n' + keyword}
        accessoryLeft={renderBackAction}
        accessoryRight={renderRightAction}
      />
      <Divider />
      <View style={{ flex: 1 }}>
        <ScrollView style={{ height: Dimensions.get('window').height - 200 }}>
          <ContentView navigation={navigation} range={range} keyword={keyword} type={type} />
        </ScrollView>
      </View>
    </ImageBackground>
    {/* <View style={{ height: 100, justifyContent: 'center' }}>
      <Button
        size='giant'
        style={{
          marginHorizontal: 16,
          marginVertical: 24,
        }}
        onPress={() => {navigation.navigate(AppRoute.CHAM_CONG_CREATE);}}>
        Chấm công
      </Button>
    </View> */}
  </SafeAreaLayout>
};

const styles = StyleService.create({
  container: {
    maxWidth: Dimensions.get('window').width > 500 ? 500 : Dimensions.get('window').width,
    minWidth: 390,
    flex: 1,
    alignSelf: 'center'
  },
});

const YeuCauSearchForm = ({ onSubmit, farmName }): React.ReactElement => {
  const [range, setRange] = React.useState<CalendarRange<Date>>(defaultRange);
  const [keyword, setKeyword] = React.useState<string>('');
  const [selectedMonthIndex, setSelectedMonthIndex] = React.useState<IndexPath | IndexPath[]>(new IndexPath(0));
  const [selectedYearIndex, setSelectedYearIndex] = React.useState<IndexPath | IndexPath[]>(new IndexPath(2));
  const [selectedCustomerNameIndex, setSelectedCustomerNameIndex] = React.useState<IndexPath | IndexPath[]>(new IndexPath(0));
  const [selectedTypeIndex, setSelectedTypeIndex] = React.useState<IndexPath | IndexPath[]>(new IndexPath(0));

  const [customerNameList, setCustomerNameList] = React.useState<string[]>([]);
  const [typeNameList, setTypeNameList] = React.useState<string[]>([]);

  const displayCustomerNameValue = customerNameList.length > 0 ? (customerNameList[selectedCustomerNameIndex?.row]['companyName']) : '';
  const displayValue = selectedMonthIndex?.row != 12 ? 'Tháng ' + ([...Array(12).keys()][selectedMonthIndex?.row] + 1) : 'Tất cả';
  const displayYearValue = 'Năm ' + ([...Array(12).keys()][selectedYearIndex?.row] + 2022);
  const displayTypeNameValue = typeNameList.length > 0 ? (typeNameList[selectedTypeIndex?.row]) : ''
  const loadData = async () => {

    // const data = await AppStorage.get<any>(AppConst.USER_INFO);
    // const companyName = data?.companyName;
    setTimeout(async () => {
      var email = await AppAuthService.getName();
      // tslint:disable-next-line: max-line-length
      if (email.includes('@')) {
        AppFetch.get(`CustomerListMobile/GetCustomerItemByEmail?email=${email}`).then(async (rp: any[]) => {
          if (rp) {
            rp.push()
            setCustomerNameList(rp.map(x => x));
          }
        });
      }
      else {
        AppFetch.get(`CustomerListMobile/GetCustomerItemByPhoneNumber?phoneNumber=${email}`).then(async (rp: any[]) => {
          let customerList: any[] = [...rp];
          if (customerList.length === 0) {
            customerList.push({companyName: farmName});
          }
          setCustomerNameList(customerList.map(x => x));

        });
      }
      AppFetch.get(`Testing_Info/Type_Testing_Info`).then(async (rp: string[]) => {
        if (rp) {
          rp.unshift('Tất cả');
          setTypeNameList(rp.filter(x => x).map(x => x));
        }
      });
    }, 0);
  };
  React.useEffect(() => {
    loadData();

    // return unsubscribe;
  }, []);
  return (
    <View style={{ margin: 'auto', alignItems: 'center', justifyContent: 'center', backgroundColor: 'none' }}>
      <Card style={{ width: Dimensions.get("window").width - 50 }}>
        {/* <Input
          label='Tìm kiếm'
          value={keyword}
          onChangeText={setKeyword} /> */}
        {/* <RangeDatepicker
          label='Chọn ngày'
          range={range}
          min={new Date(1900,0,0)}
          onSelect={nextRange => setRange(nextRange)}
        /> */}
        <Select label={'Chọn tháng'}
          selectedIndex={selectedMonthIndex}
          value={displayValue}
          onSelect={index => setSelectedMonthIndex(index)}>

          {/* {[...Array(60).keys()].map((v, i) => <SelectItem title={`Tháng ${i % 12 + 1}/${(new Date()).getFullYear() - Math.floor(i/12)}`}></SelectItem>).reverse()} */}
          {[...Array(12).keys()].map((v, i) => <SelectItem key={i} title={`Tháng ${i % 12 + 1}`}></SelectItem>)}
          <SelectItem title="Tất cả"></SelectItem>
        </Select>

        <Select label={'Chọn năm'}
          selectedIndex={selectedYearIndex}
          value={displayYearValue}
          onSelect={index => setSelectedYearIndex(index)}>

          {[...Array(20).keys()].map((v, i) => <SelectItem key={i} title={`Năm ${2022 + i}`}></SelectItem>)}
        </Select>
        {<Select
          style={{ flexBasis: 'auto' }}
          label={'Chọn khách hàng'}
          selectedIndex={selectedCustomerNameIndex}
          value={displayCustomerNameValue}
          onSelect={index => setSelectedCustomerNameIndex(index)}>
          {customerNameList.map((v, i) => <SelectItem key={i} title={`${v['companyName']}`}></SelectItem>)}
        </Select>}
        {<Select label={'Chọn loại xét nghiệm'}
          selectedIndex={selectedTypeIndex}
          value={displayTypeNameValue}
          onSelect={index => setSelectedTypeIndex(index)}>

          {typeNameList.map((v, i) => <SelectItem key={i} title={`${v}`}></SelectItem>)}
        </Select>}


        <Button style={{ marginTop: 20 }} onPress={() => {
          let rs = {
            startDate: moment().set({ 'year': (selectedYearIndex?.row + 2022), 'month': (selectedMonthIndex?.row), 'date': 1 }).toDate(),
            endDate: moment().set({ 'year': (selectedYearIndex?.row + 2022), 'month': (selectedMonthIndex?.row) }).endOf('month').toDate()
          } as CalendarRange<Date>;
          if (selectedMonthIndex?.row == 12) {
            rs = {
              startDate: moment().set({ 'year': (selectedYearIndex?.row + 2022) }).startOf('year').toDate(),
              endDate: moment().set({ 'year': (selectedYearIndex?.row + 2022) }).endOf('year').toDate()
            } as CalendarRange<Date>;
          }
          return onSubmit(rs, customerNameList.length > 0 ? customerNameList[selectedCustomerNameIndex?.row]['companyName'] : '',
            typeNameList.length > 0 ? typeNameList[selectedTypeIndex?.row] : '');
        }}>
          Chọn
        </Button>
      </Card>
    </View>);
};

