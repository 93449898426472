import React from 'react';
import { StyleSheet, View, Text, Dimensions, ImageBackground } from 'react-native';
import { Divider, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import { MenuGridList } from '../../components/menu-grid-list.component';
import { MenuIcon } from '../../components/icons';
import { data } from './data';
import { AppFetch } from '../../../src/services/app-fetch.service';
import { AppAuthService } from '../../../src/services/app-auth.service';
import { AppStorage } from '../../../src/services/app-storage.service';
import { AppConst } from '../../../src/app/app-const';
import { AppRoute } from '../../../src/app/app-routes';
import { useNavigation } from '@react-navigation/native';

export const LayoutsScreen = (props): React.ReactElement => {
  const navigation = useNavigation();
  const [name, setName] = React.useState<string>();
  const onItemPress = (index: number): void => {
    if (typeof data[index].route === 'string' || data[index].route instanceof String) {
      navigation.navigate(data[index].route.toString(), {farmName: name});
    } else {
      props.navigation.navigate(data[index].route[0], { screen: data[index].route[1] });
    }
  };


  const loadData = async () => {
    setTimeout(async () => {
      var email = await AppAuthService.getName();
      if (!email) {
        props.navigation.navigate(AppRoute.AUTH);
      }
      // setName(email);
      // tslint:disable-next-line: max-line-length
      // AppFetch.get(`CustomerListMobile/GetCustomerItemByEmail?email=${email}`).then(async (rp : any[]) => {
      //   if(rp){
      //     setName(rp['companyName']);
      //   }
      // });

      const data = await AppStorage.get<any>(AppConst.USER_INFO);
      const displayName = data?.displayName;
      if (displayName) {
        setName(displayName);
      }
    }, 100);
  };
  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener('focus', () => {
      loadData();
    });
    loadData();

    // return unsubscribe;
  },
    [props.navigation]);

  const renderDrawerAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={MenuIcon}
      onPress={props.navigation.toggleDrawer}
    />
  );

  return (
    <SafeAreaLayout
      style={styles.safeArea}
      insets='top'>
      <ImageBackground
        source={require('../../assets/images/bg-02.jpg')}
        resizeMode="stretch" style={{
          flex: 1,
          // height: 140,
          justifyContent: 'center',
        }}>
        <TopNavigation
          title={name}
          accessoryLeft={renderDrawerAction}
        />
        <Divider />
        <MenuGridList
          style={{
            backgroundColor: 'rgba(52, 52, 52, 0.0)'
          }}
          data={data}
          onItemPress={onItemPress}
        />
        {/* <View>
      <Text style={{textAlign: 'center'}}>R.E.P LAB 2024</Text>
      <Text style={{textAlign: 'center'}}>Hotline: 0327 61 5454</Text>
      </View> */}
      </ImageBackground>
    </SafeAreaLayout>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    maxWidth: Dimensions.get('window').width > 500 ? 500 : Dimensions.get('window').width,
    minWidth: 390,
    alignSelf: 'center'
  },
});
