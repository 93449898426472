import React from 'react';
import { TouchableWithoutFeedback, StyleSheet, View, Alert, Dimensions } from 'react-native';
import {
  Divider,
  List,
  ListItem,
  Text,
  TopNavigation,
  TopNavigationAction,
  useTheme,
  Icon,
  IconElement,
  Input,
  Button,
  Layout,
} from '@ui-kitten/components';
import { ArrowIosBackIcon, ArrowIosForwardIcon } from '../../components/icons';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import { AppRoute } from '../../app/app-routes';
import { BottomTabBar } from '@react-navigation/bottom-tabs';
import { AppFetch } from '../../services/app-fetch.service';
import { AppAuthService } from '../../services/app-auth.service';
import Toast from 'react-native-root-toast';
import AppUtils from '../../app/app-utils';
import { AppConst } from '../../../src/app/app-const';
import { AppStorage } from '../../../src/services/app-storage.service';


export const PasswordScreen = ({ navigation }): React.ReactElement => {

  const theme = useTheme();


  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={ArrowIosBackIcon}
      onPress={() => AppUtils.goBack(navigation, AppRoute.HOME)}
    />
  );

  const AlertIcon = (props): IconElement => (
    <Icon
      {...props}
      name='alert-circle-outline'
    />
  );

  const [value1, setValue1] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [value3, setValue3] = React.useState('');
  const [secureTextEntry, setSecureTextEntry] = React.useState(true);

  const toggleSecureEntry = (): void => {
    setSecureTextEntry(!secureTextEntry);
  };

  const renderIcon = (props): React.ReactElement => (
    <TouchableWithoutFeedback onPress={toggleSecureEntry}>
      <Icon
        {...props}
        name={secureTextEntry ? 'eye-off' : 'eye'}
      />
    </TouchableWithoutFeedback>
  );

  const renderCaption = (): React.ReactElement => {
    return (
      <View style={styles.captionContainer}>
        {AlertIcon(styles.captionIcon)}
        <Text style={styles.captionText}>
          Should contain at least 8 symbols
        </Text>
      </View>
    );
  };
  const onSave = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    const companyName = data?.name;
    let body;
    if(companyName)
    {
      body = {
        email: data?.phoneNumber,
        password: newPassword,
      };
    }
    else{
      body = {
        email: await AppAuthService.getName(),
        password: newPassword,
      };
    }
    AppFetch.post(`CustomerListMobile/UpdatePassword`, body).then(rp => {
      Alert.alert('Đã gửi');
      Toast.show('Đã đổi mật khẩu thành công');
      navigation.navigate(AppRoute.ROOT, { screen: AppRoute.HOME });
    });
  };

  return (
    <SafeAreaLayout
      style={styles.safeArea}
      insets='top'>
      <TopNavigation
        title='Đổi mật khẩu'
        accessoryLeft={renderBackAction}
      />
      <Divider/>
      <Layout
        style={styles.formContainer}
        level='1'>
        {/* <Input
          style={{marginBottom:20}}
          value={value1}
          label='Mật khẩu cũ'
          placeholder=''
          // caption={renderCaption}
          accessoryRight={renderIcon}
          secureTextEntry={secureTextEntry}
          onChangeText={nextValue => setValue1(nextValue)}
        /> */}

        <Input
          style={{marginBottom:10}}
          value={newPassword}
          label='Mật khẩu mới'
          placeholder=''
          //caption={renderCaption}
          accessoryRight={renderIcon}
          secureTextEntry={secureTextEntry}
          onChangeText={nextValue => setNewPassword(nextValue)}
        />

        {/* <Input
          value={value3}
          label='Nhập lại mật khẩu'
          placeholder=''
          //caption={renderCaption}
          accessoryRight={renderIcon}
          secureTextEntry={secureTextEntry}
          onChangeText={nextValue => setValue3(nextValue)}
        /> */}
      </Layout>
      <Button
        style={{marginHorizontal:16,marginBottom:50}}
        size='giant'
        onPress={() => onSave()}
      >Save</Button>

    </SafeAreaLayout>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    maxWidth: Dimensions.get('window').width > 500 ? 500 : Dimensions.get('window').width,
    minWidth: 390,
    alignSelf: 'center'
  },
  headerTitle: {
    paddingHorizontal: 8,
  },
  headerDivider: {
    marginVertical: 8,
  },
  listContent: {
    padding: 8,
  },
  item: {
    marginVertical: 4,
  },
  captionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captionIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  captionText: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'opensans-regular',
    color: '#8F9BB3',
  },
  formContainer: {
    flex: 1,
    paddingTop: 32,
    paddingHorizontal: 16,
  }
});
