import React, { useCallback, useMemo, useRef, useState } from 'react';
import { RequestListItem } from 'src/model/cham-cong-item.model';
import { AppFetch } from '../../../services/app-fetch.service';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
import { ChamCongList } from './extra/cham-cong-list.component';
import { data } from './extra/data';
import { AppRoute } from '../../../app/app-routes';
import { Button, CalendarRange, Card, Input, ModalService, Text } from '@ui-kitten/components';
import moment from 'moment';
import { ActivityIndicator, Alert, Dimensions, View } from 'react-native';
import { AppAuthService } from '../../../services/app-auth.service';
import Toast from 'react-native-root-toast';
import * as WebBrowser from 'expo-web-browser';
import { AppStorage } from '../../../../src/services/app-storage.service';
import { AppConst } from '../../../../src/app/app-const';

export interface Props {
  range: CalendarRange<Date>;
  keyword: string;
  type: string;
  navigation: any;
}
export default (props: Props): LayoutListElement => {
  const { navigation, range, keyword, type } = props;
  // const [data, setData] = React.useState<RequestListItem[]>([{
  //   Customer: 'Nguyễn Duy Lân',
  //   requestNo: 'RQ220018',
  //   ExpectationDate: 'new Date()',
  //   Recorded: 1,
  //   TotalSample: 5,
  //   TotalSpec: 5,
  //   companyName: '',
  //   contactName: 'Nguyễn Duy Lân',
  //   receivceDate: 'new Date()',
  // }as RequestListItem]);
  const [data, setData] = React.useState<RequestListItem[]>();
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  let [emptyListComponent, setEmptyListComponent] = React.useState<JSX.Element>(<Text></Text>);
  const loadData = () => {
    setIsLoading(true);
    const { startDate, endDate } = range as CalendarRange<Date>;
    let search = keyword;
    let typeSearch = (type == 'Tất cả') ? '' : type;
    if (startDate && endDate) {
      // tslint:disable-next-line: max-line-length
      AppFetch.get(`ManagementRequest?tuNgay=${moment(startDate).format('YYYY/MM/DD')}&denNgay=${moment(endDate).format('YYYY/MM/DD')}&type=${typeSearch}&customer=${search}`)
      .then(async (rp: any[]) => {
        var email = await AppAuthService.getName();
        const data = await AppStorage.get<any>(AppConst.USER_INFO);
        const companyName = data?.companyName;
        if (!email) {
          props.navigation.navigate(AppRoute.AUTH);
        }
        // if (!search) {
        //   if (rp.filter(x => x.email == email).length > 0) {
        //     search = rp.filter(x => x.email == email)[0].Customer
        //   }
        // }
        if (companyName) {
          search = companyName;
        }
        if (data?.phoneNumber) {
          email += ' ' + data?.phoneNumber
        }
        let dataRs = rp.filter(x => email.includes(x.email));
        // if(keyword){
        //   dataRs = dataRs.filter(x => x.Customer.includes(keyword));
        // }
        setData(dataRs);
        if (dataRs.length == 0) {
          if (moment(startDate).month() == moment(endDate).month())
            setEmptyListComponent(<Text> Tháng {moment(startDate).month() + 1}/{moment(startDate).year()} không có phiếu yêu cầu</Text>);
          else
            setEmptyListComponent(<Text> Năm {moment(startDate).year()} không có phiếu yêu cầu</Text>);
        }
        else {
          setEmptyListComponent(<Text></Text>);
        }
        setIsLoading(false);
      });
    }
  };
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadData();
    });
    loadData();

    // return unsubscribe;
  },
    [navigation, range]);


  const onItemPress = (index: number): void => {
    // navigation.navigate(AppRoute.YEU_CAU_DETAIL, { requestNo: data[index].requestNo });
  };

  const onItemLongPress = (index: number): void => {
    // navigation.navigate(data[index].route);
  };
  const onValueTextChange = (value: string, index: number): void => {
    const model = data;
    model[index].techComment = value;
    setData(model);
  };
  let modalID = '';
  const onItemSubmit = (index: number): void => {
    const contentElement = <TextCommentSubmitForm onSubmit={onSubmit(index)} techComment={data[index].techComment}></TextCommentSubmitForm>;
    modalID = ModalService.show(contentElement, { onBackdropPress: hideModal, backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } });
  };
  const onItemViewPdf = (index: number): void => {
    AppFetch.get(`../RecordResult/GetPdfPath?requestNo=${data[index].requestNo}`).then(async (rp: any[]) => {
      if (rp && rp.length > 0) {
        WebBrowser.openBrowserAsync(`https://replab.b24.vn/data/pdf/${rp}`);
      }
      else {
        Toast.show('Phiếu yêu cầu chưa có kết quả');
      }
    }).catch(() => Alert.alert('Phiếu yêu cầu chưa có kết quả'));
  };
  const hideModal = () => {
    ModalService.hide(modalID);
  };
  const onSubmit = (index) => (_techComment) => {
    const body = { textValue: _techComment, requestNo: data[index].requestNo };
    AppFetch.post(`ManagementRequestDetail`, body).then(rp => {
      Alert.alert('Đã gửi');
      loadData();
    });
    hideModal();
  };
  return (
    <>
      {!isLoading &&
        <View>
          <ChamCongList
            data={data}
            onItemPress={onItemPress}
            onItemLongPress={onItemLongPress}
            onValueTextChange={onValueTextChange}
            onItemSubmit={onItemSubmit}
            onItemViewPdf={onItemViewPdf}
          />
        </View>
      }
      {!isLoading && emptyListComponent}

      {isLoading &&
        <View style={{
          width: '100%',
          height: Dimensions.get("window").height -200,
          position: 'absolute',
          zIndex: 20 
        }}>
          <ActivityIndicator style={{
            zIndex: 21,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }} size={50} />
        </View>
      }
    </>

  );
};

const TextCommentSubmitForm = ({ onSubmit, techComment }): React.ReactElement => {
  const [textComment, setTextComment] = React.useState<string>(techComment);


  return (
    <View style={{ margin: 'auto', alignItems: 'center', justifyContent: 'center', backgroundColor: 'none', minHeight: 80 }}>
      <Card>
        <Input
          label='Nội dung'
          value={textComment}
          multiline={true}
          numberOfLines={8}
          onChangeText={setTextComment} />
        <Button style={{ marginTop: 20 }} onPress={() => onSubmit(textComment, Toast.show('Đã ghi chú thành công'))}> Gửi </Button>
      </Card>
    </View>);
};