import { Alert, NativeModules } from 'react-native';
import { AppConst } from '../app/app-const';
import { AppStorage } from './app-storage.service';
import { authorize, AuthorizeResult, prefetchConfiguration, refresh, revoke } from 'react-native-app-auth';
import { AppRoute } from '../app/app-routes';

const config = {
  issuer: 'https://xbaccount.b24.vn',
  clientId: 'Xbook_pro_dong_tam_mobile_dev',
  redirectUrl: 'com.xbookdongtam.auth:/oauthredirect',
  scopes: ['openid', 'profile', 'offline_access'],
};
export class AppAuthService {
  static getConfig = () => []

  static handleAuthorize = async (navigation) => {
    try {
      // const newAuthState = await authorize({
      //   ...config,
      // });
      // const result = {
      //   hasLoggedInOnce: true,
      //   provider: 'identityserver',
      //   ...newAuthState,
      // };

      // AppStorage.set(AppConst.USER_IDENTITY_INFO, JSON.stringify(result));
    } catch (error) {
      Alert.alert('Failed to log in', error.message);
    }
  }

  static isLoggedIn:() => Promise<boolean> = async () => {
    const data = await AppStorage.get<AuthorizeResult>(AppConst.USER_IDENTITY_INFO);
    const token = data?.accessToken;
    return !!token;
  }

  static getToken: () => Promise<string> = async () => {
    const data = await AppStorage.get<AuthorizeResult>(AppConst.USER_IDENTITY_INFO);
    const token = data?.accessToken;
    return token;
  }

  static getName: () => Promise<string> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    const token = data?.name;
    return token;
  }
}
